import axios from "axios";

const HTTP = axios.create({
  /*baseURL: `http://localhost:8080/`,*/
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    /* "Access-Control-Allow-Origin":"*" */
    /* Authorization: '' */
    "Content-Type": "application/json",
  },
});

export { HTTP };
