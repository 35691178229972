<template>
  <footer class="footer">
    <div class="crypto-icons">
      <IconCrypto coinname="ETH" color="white" format="svg" />
      <IconCrypto coinname="BTC" color="white" format="svg" />
      <IconCrypto coinname="XRP" color="white" format="svg" />
      <IconCrypto coinname="ADA" color="white" format="svg" />
      <IconCrypto coinname="EOS" color="white" format="svg" />
      <hr class="hr1" />
    </div>

    <div class="medias">
      <div><p class="font-weight-bold">Join us!</p></div>
      <a href="" target="_blank" id="sosialmedia"
        ><b-icon icon="twitter" class="h5 mb-2"></b-icon
      ></a>
      <a
        href="https://www.linkedin.com/in/hanif-tadjik"
        target="_blank"
        id="sosialmedia"
        ><b-icon icon="linkedin" class="h5 mb-2"></b-icon
      ></a>
      <a href="https://github.com/Hanifff" target="_blank" id="sosialmedia"
        ><b-icon icon="github" class="h5 mb-2"></b-icon
      ></a>
      <a
        href="https://www.instagram.com/haenif_/"
        target="_blank"
        id="sosialmedia"
        ><b-icon icon="instagram" class="h5 mb-2"></b-icon
      ></a>
      <a href="https://discord.gg/74bxvCbF" target="_blank" id="sosialmedia"
        ><b-icon icon="discord" class="h5 mb-2"></b-icon
      ></a>
    </div>
    <div class="info">
      <div id="logo-navn"><p class="font-weight-bold">Hanif Tadjik</p></div>
      <p class="font-weight-normal footer-text">
        Journeying through AI, NLP, blockchain, NFTs, Web 3.0, DAO, Metaverse,
        and Cloud innovations.
      </p>
    </div>
    <div class="copy-right">
      <hr class="hr2" />
      <div class="copy">
        <small
          >&copy; 2022: <a href="https://haniftadjik.tech">Hanif Tadjik</a></small
        >
      </div>
      <div class="policy">
        <small
          ><a href="/privacy"
            ><router-link to="/privacy">Privacy policy</router-link></a
          >
          <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="/terms"
            >Terms of service</a
          > -->
        </small>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>

<style>
.footer {
  position: relative;
  width: 100%;
  bottom: 0;
  right: 0;
  height: 350px;
  background-color: #1d3461;
}

div.crypto-icons {
  height: 75px;
  line-height: 75px;
  text-align: center;
}

div.crypto-icons > * {
  padding-left: 5%;
  padding-right: 5%;
}

.hr1 {
  margin: auto;
  max-width: 100%;
  border: 0;
  height: 3px;
  background-image: linear-gradient(to right, #6290c8, #829cbc, #6290c8);
}

.medias {
  float: right;
  padding-top: 25px;
  margin-right: 10%;
  max-width: 30%;
  color: #fff;
}

#sosialmedia {
  color: #fff;
  margin: 5px;
}
#sosialmedia:hover {
  color: #6290c8;
}

.info {
  padding-top: 25px;
  float: left;
  margin-left: 10%;
  color: #fff;
  max-width: 30%;
  text-align: left;
}

div.copy-right {
  position: absolute;
  bottom: 4%;
  /* margin-top: auto; */
  width: 100%;
  height: 50px;
  font-size: 0.85vw;
}

@media screen and (max-width: 600px) {
  div.copy-right {
    font-size: 2.5vw;
  }
  .footer-text {
    font-size: smaller;
  }
}

.hr2 {
  margin: auto;
  max-width: 80%;
  border: 0;
  height: 3px;
  background-image: linear-gradient(to right, #6290c8, #829cbc, #6290c8);
}

.copy {
  position: absolute;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin-left: 10%;
  color: #fff;
}

.copy a {
  text-decoration: none;
  color: #fff;
}
.copy a:hover {
  text-decoration: none;
  color: #6290c8;
}

.policy {
  position: relative;
  float: right;
  height: 50px;
  line-height: 50px;

  text-align: center;
  margin-right: 10%;
  color: #fff;
}

.policy a {
  text-decoration: none;
  color: #fff;
}
.policy a:hover {
  text-decoration: none;
  color: #6290c8;
}
</style>
