import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueCryptojs from "vue-cryptojs";
import "./assets/css/main.css";
import {
  BootstrapVue,
  IconsPlugin,
  BadgePlugin,
  CarouselPlugin,
  SpinnerPlugin,
} from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import IconCrypto from "vue-cryptocurrency-icons";
import { getData, IsMobile } from "./components/helpers/utils.js"; // is device a mobile?
import VDragged from "v-dragged";
import VueSocialSharing from "vue-social-sharing";
import CookieLaw from "vue-cookie-law";
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTwitter,
  faLinkedin,
  faFacebook,
  faTelegram,
  faReddit,
} from "@fortawesome/free-brands-svg-icons";
library.add(faTwitter, faFacebook, faLinkedin, faTelegram, faReddit);
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VueSocialSharing);
Vue.use(IconCrypto);
Vue.use(CarouselPlugin);
Vue.use(VueCryptojs);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(BadgePlugin);
Vue.use(SpinnerPlugin);
Vue.config.productionTip = false;
Vue.use(VueMoment, {
  moment,
});
Vue.use(VDragged);
Vue.prototype.$scrollToTop = () => window.scrollTo(0, 0);
Vue.component("CookieLaw", CookieLaw);
/*----------------Cookie ---------------------------------------*/
// Require dependencies
var VueCookie = require("vue-cookie");
// Tell Vue to use the plugin
Vue.use(VueCookie);
/*----------------------------------------------------------------*/
Vue.prototype.$skDecEnc = process.env.VUE_APP_CRYPTO_EN_DEC_SECRET_KEY;

Vue.config.errorHandler = function (err, vm, info) {
  console.log(`***This is custom Error: ${err.toString()}\nInfo: ${info}`);
};

window.onerror = function (msg, url, line, col, error) {
  console.log(
    `***This is window custom error: ${error.toString()}\nInfo: ${msg}`
  );
};

/* new Vue({
  render: (h) => h(App),
  router,
  data: {
    isMobile: IsMobile(),
  },
}).$mount("#app"); */

//Can be used to fetch data before render
let fetchedBlogs = [];
(async () => {
  fetchedBlogs = await getData("GetBlogs/en");
  new Vue({
    render: (h) => h(App),
    router,
    data: {
      fetchedBlogs: fetchedBlogs,
      isMobile: IsMobile(),
    },
    created() {},
  }).$mount("#app");
})();
