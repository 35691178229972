import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
function lazyLoad(view) {
  return () => import("./components/" + view);
}
const routes = [
  {
    path: "/",
    name: "Blogs",
    component: lazyLoad("Blogs"),
    props: true,
  },
  {
    path: "/search",
    name: "Search",
    component: lazyLoad("Search"),
    props: true,
  },
  {
    path: "/category",
    name: "Category",
    component: lazyLoad("Category"),
    props: true,
  },
  {
    path: "/notfound",
    name: "NotFound",
    component: lazyLoad("NotFound"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: lazyLoad("Privacy"),
  },
  {
    path: "/:id",
    name: "Blog",
    component: lazyLoad("Blog"),
    props: (route) => ({
      blogId: route.params.id, // maps route param "id" to prop "profileId"
    }),
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  routes: routes,
  mode: "history",
  // base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
