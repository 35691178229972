<template>
  <div>
    <b-navbar
      :class="{ scroll_color: scrollPosition > 60 }"
      type="light"
      toggleable="lg"
      fixed="top"
    >
      <b-navbar-brand
        ><a href="https://haniftadjik.com">
          <img
            src="../assets/figure/log_X2.png"
            alt="Blockchain NFT Metaverse DAO Consensus DID Web 3.0 AI ChatGPT"
            width="40"
            height="40"
            class="d-inline-block align-text-center"
          />
          <span class="span-name">Hanif Tadjik</span>
          <span class="span-separator"></span>
          <span class="span-blog">Blog</span>
        </a>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse">
        <template>
          <b-icon icon="chevron-bar-down" class="icontoggler"></b-icon>
        </template>
      </b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav center>
        <b-navbar-nav class="navLists">
          <b-nav-item href="https://haniftadjik.com"><a>Home</a></b-nav-item>
          <b-nav-item
            :to="{ name: 'Blogs' }"
            :class="{
              active: $route.name === 'Blogs' || $route.name === 'Blog',
            }"
            ><a>Blog</a></b-nav-item
          >
          <b-nav-item href="https://haniftadjik.com/about"
            ><a>About</a></b-nav-item
          >
          <b-nav-item href="https://haniftadjik.com/contact"
            ><a>Contact</a></b-nav-item
          >
        </b-navbar-nav>

        <div class="no-filters">
          <b-navbar-nav :class="{ scrolled: scrollPosition > 60 }">
            <!-- <b-nav-form> -->
            <input
              size="md"
              class="mr-sm-2 search"
              no-caret
              placeholder="Search.."
              maxlength="250"
              v-model="search"
              pattern="[a-zA-Z0-9-åÅæÆøØ-آابپتثجچحخدذرزژسشصضطظعغفقکگلمنوهی ]+"
              @keyup.enter="searchResults"
            />
            <!-- </b-nav-form> -->
            <b-nav-item-dropdown class="lang-dropdown" no-caret size="sm" right>
              <template #button-content>
                <b-icon id="iconi" icon="globe2" aria-hidden="true"></b-icon>
              </template>
              <a class="dropdown-item" href="https://haniftadjik.com"
                >English</a
              >
              <a class="dropdown-item" href="https://haniftadjik.com/no"
                >Norsk</a
              >
              <a class="dropdown-item" href="https://haniftadjik.com/fa"
                >Farsi</a
              >
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </div>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrollPosition: null,
      search: "",
    };
  },
  methods: {
    updateScroll() {
      if (this.$route.name == "Blog") {
        this.scrollPosition = window.scrollY;
      } else {
        this.scrollPosition = null;
      }
    },
    searchResults() {
      localStorage.setItem("search", this.search);
      this.search = "";
      if (this.$route.name === "Search") {
        window.location.reload();
      } else {
        this.$router.push({
          name: "Search",
          query: { search: this.$route.query.search },
        });
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
};
</script>

<style scoped>
div {
  top: 0;
  left: 0;
  height: fit-content;
}

.navbar {
  /* overflow: hidden; */
  max-width: 100%;
  background: #ffff;
  box-shadow: 1px 1px 7px 0px #829cbc;
  min-height: 70px !important;
  padding: 0px;
  z-index: 4;
  transform: scale(1.02);
  /* transition: top 0.3s; */
  touch-action: none;
}
.navbar-nav {
  justify-content: right !important;
}
.navbar-toggler {
  margin-right: 3%;
  vertical-align: middle;
}

.navbar-brand {
  margin-left: 4%;
}

.navbar-brand img {
  margin-right: 2%;
}

.navbar-brand .span-name {
  color: #1d3461;
  padding-left: 4%;
  padding-right: 4%;
  font-weight: bolder;
  font-size: 1.5rem;
}
.navbar-nav .nav-item {
  margin-right: 2%;
}

.navbar-nav .nav-item a {
  text-decoration: none;
  color: #376996;
  font-size: 1.15rem;
}
.navbar-nav .nav-item a:hover {
  text-decoration: none;
  color: #1d3461;
}

#logo {
  margin-right: 1%;
  height: 40;
  width: 40;
}

.navbar-nav .lang-dropdown {
  text-align: center;
  width: 100px;
  touch-action: none;
}
.dropdown-menu {
  padding: 0 !important;
  min-width: none !important;
  max-width: min-content;
}
.lang-dropdown:focus {
  outline: red;
}

.navbar-nav .nav-item .dropdown-item {
  font-size: 0.9rem;
  color: #1d3461;
  /* width: 5rem; */
  margin-right: 0px;
}
.navbar-nav .nav-item .dropdown-item:hover {
  color: #376996;
}
.navbar-nav .nav-item .dropdown-item:focus {
  background-color: #376996;
}

#iconi {
  transition: all 0.3s ease-in-out;
  color: #376996;
}
#iconi:hover {
  transform: scale(1.25);
  color: #1d3461;
}
.icontoggler {
  color: #1d3461;
}

.active a {
  color: #1d3461 !important;
  font-weight: bold;
}

.span-blog {
  padding-left: 5%;
  color: #1d3461;
}
.span-separator {
  border-left: 1px solid #829cbc;
}
.navLists {
  width: 50%;
}
div.no-filters {
  width: 50%;
  vertical-align: middle;
  justify-content: center !important;
  margin-right: 10%;
}

.search {
  border: 0.2px solid #829cbc;
  outline: 0;
  padding-inline: 10px;
  margin-left: 5%;
}

@media screen and (max-width: 800px) {
  .search {
    margin-left: 10%;
  }
}
.search:focus {
  border: 0.2px solid #376996;
  box-shadow: 0.5px 2px 3px 1px #829cbc;
}
.search:hover {
  border: 0.2px solid #376996;
  box-shadow: 0.5px 2px 3px 1px #829cbc;
}
a {
  text-decoration: none;
}
/* ------------------------------------------------------------------- */
.scroll_color {
  /* background: #829cbc; */
  /* background: #ffff;
  border-bottom: none;
  box-shadow: 0px 0px 2px 0px #829cbc; */
  top: -80px;
}
</style>
