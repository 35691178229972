import { HTTP } from "../../HTTP";
import router from "../../router";
import CryptoJS from "crypto-js";

async function getData(endpoint) {
  try {
    let results = [];
    await HTTP.get("GetBlogs/en").then((response) => {
      results = response.data.data;
    });
    return results;
  } catch (error) {
    throw new Error("could not fetch the data from the endpoint: " + endpoint);
  }
}

function ToBlog(id, title) {
  const encryptedId = CryptoJS.AES.encrypt(
    id.toString(),
    process.env.VUE_APP_CRYPTO_EN_DEC_SECRET_KEY
  ).toString();
  localStorage.setItem("id", encryptedId);
  router.push({
    name: "Blog",
    params: {
      id: title.replace(/\s+/g, "-").toLowerCase(),
    },
  });
}

/* mobile */
function IsMobile() {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  } else {
    return false;
  }
}

export { getData, IsMobile, ToBlog };
